<template>
  <div class="profile-picture-edit relative rounded-full w-36 h-36 bg-neutral-200 outline outline-2 -outline-offset-2 outline-white/50 group">
    <SkeletonLoader
      v-if="profileStore.loading"
      class="relative loader opacity-50"
    />
    <template v-else>
      <div v-if="picture">
        <img
          :src="picture"
          alt="Profile Picture"
          class="profile-picture"
        >
        <div class="hidden group-hover:flex items-end gap-1 absolute -bottom-1 right-2">
          <label
            class="border-2 border-white bg-blue-400 text-white rounded-full cursor-pointer"
            aria-label="replace profile picture"
          >
            <SvgIcon
              name="refresh"
              class="w-5 h-5"
            />
            <input
              id="file-input"
              type="file"
              class="hidden"
              @change="handleFileInput"
            >
          </label>
          <button
            class="border-2 border-white bg-red-600 text-white rounded-full cursor-pointer mb-4"
            aria-label="remove profile picture"
            @click="$app.modal.open(ConfirmModal, {}, { confirm: profileStore.removePicture })"
          >
            <SvgIcon
              name="close"
              class="w-5 h-5"
            />
          </button>
        </div>
      </div>
      <label
        v-else
        for="file-input"
        class="file-input-label flex justify-center items-end outline outline-2 -outline-offset-2 outline-neutral-200 cursor-pointer"
        :class="dragging && 'bg-blue-400 outline-blue-400'"
        @drop="handleDrop"
        @dragover.prevent="dragging = true"
        @dragleave="dragging = false"
      >
        <DefaultProfilePicture />
        <button
          class="border-2 border-white bg-green-500 text-white rounded-full absolute bottom-2 right-2 p-0.5 z-10"
          aria-label="add profile picture"
          @click="openFileInput"
        >
          <SvgIcon
            name="plus"
            class="w-5 h-5"
          />
        </button>
      </label>
      <input
        id="file-input"
        type="file"
        class="hidden"
        @change="handleFileInput"
      >
    </template>
  </div>
</template>

<script setup lang="ts">
const ConfirmModal = () => import('./ConfirmDeleteProfileModal.vue')
const profileStore = supabaseStores.useProfileStore()
const picture = profileStore.usePicture()
const dragging = ref(false)
const openFileInput = () => document.getElementById('file-input')?.click()
const handleFileInput = (event: Event) => {
  const target = event.target as HTMLInputElement
  const file = target.files?.[0]
  if (file) {
    profileStore.uploadPicture(file)
    target.value = ''
  }
}
const handleDrop = (event: DragEvent) => {
  event.preventDefault()
  dragging.value = false
  const item = event.dataTransfer?.items?.[0]
  const file = item?.kind === 'file' && item.getAsFile()
  if (file) {
    profileStore.uploadPicture(file)
  }
}
</script>

<style>
.profile-picture-edit {
  .loader, .profile-picture, .file-input-label {
    @apply w-36 h-36 rounded-full;
  }
}
</style>
